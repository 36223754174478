import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSwipeable, SwipeableHandlers } from 'react-swipeable';

type SwipeDirection = "left" | "right" | "up" | "down" | null;

interface SwipeContextType {
    swipeDirection: SwipeDirection;
    setSwipeDirection: React.Dispatch<React.SetStateAction<SwipeDirection>>; // This will allow us to reset the swipe direction from the consuming component
    handlers: SwipeableHandlers;
}

const SwipeContext = createContext<SwipeContextType | undefined>(undefined);

export const useSwipe = (): SwipeContextType => {
    const context = useContext(SwipeContext);
    if (!context) {
        throw new Error("useSwipe must be used within a SwipeProvider");
    }
    return context;
};



interface SwipeProviderProps {
    children: React.ReactNode;
}

export const SwipeProvider: React.FC<SwipeProviderProps> = ({ children }) => {
    const [swipeDirection, setSwipeDirection] = useState<SwipeDirection>(null);

    const handlers = useSwipeable({
        onSwipedLeft: () => setSwipeDirection("left"),
        onSwipedRight: () => setSwipeDirection("right"),
        onSwipedUp: (eventData) => {
            // Prevent the default rubberband effect on swipe-up
            eventData.event.preventDefault();
            // If you also want to register the swipe-up event, uncomment the line below
            // setSwipeDirection("up");
        },
        onSwipedDown: () => setSwipeDirection("down"),
        trackMouse: true
    });
    
    

    useEffect(() => {
        if (swipeDirection) {
            // Add a delay before resetting to allow consumers to react to the swipe.
            // Adjust the delay if needed based on your requirements.
            const timer = setTimeout(() => {
                setSwipeDirection(null);
            }, 300); 

            return () => {
                clearTimeout(timer);
            };
        }
    }, [swipeDirection]);

    const value = { 
        swipeDirection,
        setSwipeDirection, // Expose setSwipeDirection to allow resetting from the consuming component
        handlers,
    };

    return (
        <SwipeContext.Provider value={value}>
            {children}
        </SwipeContext.Provider>
    );
};
