export const APP_CONFIG = {
  APP_TITLE: process.env.GATSBY_APP || "",
  LOCAL_APP_URL: process.env.GATSBY_LOCAL_APP_URL || "",
  LOGO: process.env.GATSBY_LOGO || "",
  GOOGLE_ANALYTICS_ID: process.env.GATSBY_GOOGLE_ANALYTICS_ID || "",
  TOKEN_EXCHANGE_URL: process.env.GATSBY_ATLAS_TOKEN_EXCHANGE_URL || "",
  B2C_CLIENT_ID: process.env.GATSBY_B2C_CLIENT_ID || "",
  B2C_AUTHORITY: process.env.GATSBY_B2C_AUTHORITY || "",
  B2C_KNOWN_AUTHORITIES: process.env.GATSBY_B2C_KNOWN_AUTHORITIES || "",
  B2C_REDIRECT_URI: process.env.GATSBY_B2C_REDIRECT_URI || "",
  SPEECH_ENDPOINT: process.env.GATSBY_SPEECH_ENDPOINT || "",
};
