module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vox","short_name":"Vox","start_url":"/","background_color":"#1e3a8a","theme_color":"#1e3a8a","display":"standalone","display_override":["fullscreen","standalone"],"icon":"src/images/Vox.svg","description":"Vox turns your email into newscasts you can listen to in minutes.","handle_links":"auto","id":"com.imperian.vox","launch_handler":{"route_to":"/","navigate_existing_client":"never"},"orientation":"portrait-primary","dir":"ltr","categories":["news","productivity","communication","media","utilities"],"lang":"en-US","prefer_related_applications":false,"scope":"/","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4e7fccb95f9f11e87d4d0e9325b3e138"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
