interface BaseEntity {
  partitionKey: string;
  rowKey: string;
  timestamp: string;
}

export interface UserEntity extends BaseEntity {
  email: string;
  model: string;
  tokens: number;
  name: string;
  paymentLink?: string;
  paymentLinkId?: string;
  conversationId: string;
  theme?: string;
  pending_messages: MessageEntity[];
  firstRun?: boolean;
}

export interface MessageEntity extends BaseEntity {
  userId: string;
  characterId: string;
  conversationId: string;
  sender: string;
  content: string;
  files?: string;
  status?: string;
  title?: string;
}

export interface IntervalEntity extends BaseEntity {
  userId: string;
  conversationId: string;
  day: number;
  hour: number;
}
export interface ApiPayloadType {
  action: string;
  payload: any;
}

export interface FeedEntity extends BaseEntity {
  title: string;
  subtitle: string;
  userId: string;
}

export interface EpisodeEntity extends BaseEntity {
  headline: string;
  subhead: string;
  userId: string;
  feedId: string;
  transcript: string;
  audioUrl: string;
  RowKey?: string;
}

export function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export const getExponentialBackoffTime = (retryCount: number) => {
  return Math.pow(2, retryCount) * 1000 + Math.random() * 1000;
};

export const isRetryableError = (error: any) => {
  return true;
};

export const devLog = (...messages: any[]) => {
  if (process.env.NODE_ENV === "development") {
    const date = new Date();
    const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;

    // Prefix "DEV" in a specific color (e.g., blue) for visual distinction
    const devPrefix = '%cDEV';
    const timePrefix = `[${formattedTime}]`;

    console.log(devPrefix, 'color: blue; font-weight: bold;', timePrefix, ...messages);
  }
};


/**
 * Helps in throttling a function based on the last time it was invoked.
 * @param lastInvoked - The last time (in milliseconds since UNIX epoch) the function was invoked.
 * @param throttleInterval - The minimum time interval (in milliseconds) between two consecutive invocations.
 * @returns - A boolean indicating whether the function can be invoked now.
 */
export const canInvokeFunction = (
  lastInvoked: number | null,
  throttleInterval: number
): boolean => {
  const currentTime = new Date().getTime();
  return !lastInvoked || currentTime - lastInvoked > throttleInterval;
};

/**
 * Update the last invoked time to the current time.
 * @returns - The current time (in milliseconds since UNIX epoch).
 */
export const updateLastInvoked = (): number => {
  return new Date().getTime();
};