import React, {
  createContext,
  useReducer,
  useContext,
  ReactNode,
  useCallback,
} from "react";
import { useUpdateProfile } from "../hooks/useProfile";
import { useAuthContext } from "./AuthProvider/AuthProvider";
import { useUserContext } from "./UserProvider";

interface WizardContextType {
  state: State;
  activePage: number;
  firstRun: boolean;
  setActivePage: (page: number) => void;
  completeTutorial: () => void;
}

interface WizardProviderProps {
  children: ReactNode;
}

interface State {
  activePage: number;
  firstRun: boolean;
}

enum ActionType {
  SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE",
  COMPLETE_TUTORIAL = "COMPLETE_TUTORIAL",
}

type Action =
  | { type: ActionType.SET_ACTIVE_PAGE; payload: number }
  | { type: ActionType.COMPLETE_TUTORIAL };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_ACTIVE_PAGE:
      return { ...state, activePage: action.payload };
    case ActionType.COMPLETE_TUTORIAL:
      return { ...state, firstRun: false };
    default:
      // Handling an unexpected action type case
      const exhaustiveCheck: never = action;
      throw new Error(`Unhandled action type: ${exhaustiveCheck}`);
  }
};

const initialState: State = {
  activePage: 1, // considering 1 as the starting page number
  firstRun: true,
};

export const WizardContext = createContext<WizardContextType | undefined>(
  undefined
);

export const useWizardContext = () => {
  const context = useContext(WizardContext);
  if (!context) {
    throw new Error("useWizardContext must be used within a WizardProvider");
  }
  return context;
};

export const WizardProvider = ({ children }: WizardProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { state: authState } = useAuthContext(); // Assuming you have an auth context
  const updateProfile = useUpdateProfile(authState.atlasToken || ""); // This is the hook from the previous step
  const { userProfile } = useUserContext(); // Assuming you have a user context
  const setActivePage = (page: number) =>
    dispatch({ type: ActionType.SET_ACTIVE_PAGE, payload: page }); // Use enum value

  const completeTutorial = useCallback(async () => {
    dispatch({ type: ActionType.COMPLETE_TUTORIAL });

    // Here, you should call your update profile method.
    try {
      // Call update profile method
      userProfile.firstRun = false;
      updateProfile.mutate(userProfile);
    } catch (error) {
      console.error("Failed to update user profile:", error);
    }
  }, [updateProfile]);

  const value = {
    state,
    activePage: state.activePage,
    firstRun: state.firstRun,
    setActivePage,
    completeTutorial,
  };

  return (
    <WizardContext.Provider value={value}>{children}</WizardContext.Provider>
  );
};
