import React, { ReactNode, createContext, useContext, useReducer } from "react";
import { EpisodeEntity } from "../utils/utils";
import _ from 'lodash';

interface EpisodeContextType {
  state: State;
  episodes: EpisodeEntity[];
  loadingEpisodes: boolean;
  activeEpisode: EpisodeEntity | null;
  setActiveEpisode: (activeEpisode: EpisodeEntity) => void;
  setEpisodes: (episodes: EpisodeEntity[]) => void;
  setLoadingEpisodes: (loadingEpisodes: boolean) => void;
}

interface EpisodeProviderProps {
  children: ReactNode;
}

interface State {
  activeEpisode: EpisodeEntity | null;
  episodes: EpisodeEntity[];
  loadingEpisodes: boolean;
}

type Action =
  | { type: "SET_ACTIVE_EPISODE"; payload: EpisodeEntity | null }
  | { type: "SET_EPISODES"; payload: EpisodeEntity[] }
  | { type: "SET_LOADING_EPISODES"; payload: boolean }

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "SET_ACTIVE_EPISODE":
      if (!_.isEqual(state.activeEpisode, action.payload)) {
        return { ...state, activeEpisode: action.payload };
      }
      return state;
    case "SET_EPISODES":
      if (!_.isEqual(state.episodes, action.payload)) {
        return { ...state, episodes: action.payload };
      }
      return state;
    case "SET_LOADING_EPISODES":
      if (state.loadingEpisodes !== action.payload) {
        return { ...state, loadingEpisodes: action.payload };
      }
      return state;
    default:
      return state;
  }
};

const initialState: State = {
  activeEpisode: null,
  episodes: [],
  loadingEpisodes: true,
};

export const EpisodeContext =
  createContext<EpisodeContextType | undefined>(undefined);

export const useEpisodeContext = () => {
  const context = useContext(EpisodeContext);
  if (!context) {
    throw new Error(
      "useEpisodeContext must be used within an EpisodeProvider"
    );
  }
  return context;
};

export const EpisodeProvider = ({ children }: EpisodeProviderProps) => {
  const [state, dispatch] = useReducer<React.Reducer<State, Action>>(reducer, initialState);

  const setActiveEpisode = (activeEpisode: EpisodeEntity) =>
    dispatch({ type: 'SET_ACTIVE_EPISODE', payload: activeEpisode });

  const setEpisodes = (episodes: EpisodeEntity[]) =>
    dispatch({ type: 'SET_EPISODES', payload: episodes });

  const setLoadingEpisodes = (loadingEpisodes: boolean) =>
    dispatch({ type: 'SET_LOADING_EPISODES', payload: loadingEpisodes });

  const episodes = state.episodes;
  const loadingEpisodes = state.loadingEpisodes;
  const activeEpisode = state.activeEpisode;

  const value = {
    state,
    episodes,
    loadingEpisodes,
    activeEpisode,
    setActiveEpisode,
    setEpisodes,
    setLoadingEpisodes,
  };

  return (
    <EpisodeContext.Provider value={value}>
      {children}
    </EpisodeContext.Provider>
  );
};
