import React, {
  createContext,
  useReducer,
  useContext,
  ReactNode,
  useEffect,
  useRef,
} from "react";
import { EpisodeEntity } from "../utils/utils";
import { devLog } from "../utils/utils";

interface AudioState {
  isPlaying: boolean;
  currentPlaybackTime: number;
  sliderPosition: number;
  activeEpisode: EpisodeEntity | null;
}

const initialState: AudioState = {
  isPlaying: false,
  currentPlaybackTime: 0,
  sliderPosition: 0,
  activeEpisode: null,
};

type AudioAction =
  | { type: "SET_PLAYBACK_STATUS"; payload: boolean }
  | { type: "SET_PLAYBACK_TIME"; payload: number }
  | { type: "SET_SLIDER_POSITION"; payload: number }
  | { type: "SET_ACTIVE_EPISODE"; payload: EpisodeEntity };

const AudioContext = createContext<{
  state: AudioState;
  dispatch: React.Dispatch<AudioAction>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

const audioReducer = (state: AudioState, action: AudioAction): AudioState => {
  switch (action.type) {
    case "SET_PLAYBACK_STATUS":
      return { ...state, isPlaying: action.payload };
    case "SET_PLAYBACK_TIME":
      return { ...state, currentPlaybackTime: action.payload };
    case "SET_SLIDER_POSITION":
      return { ...state, sliderPosition: action.payload };
    case "SET_ACTIVE_EPISODE":
      return { ...state, activeEpisode: action.payload };
    default:
      return state;
  }
};

interface AudioProviderProps {
  children: ReactNode;
}

export const AudioProvider: React.FC<AudioProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(audioReducer, initialState);
  const intervalId = useRef<NodeJS.Timeout | null>(null); // Use useRef to persist the interval ID between renders

  useEffect(() => {
    if (!state.isPlaying && intervalId.current) {
      clearInterval(intervalId.current);
    }
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [state.isPlaying]);

  return (
    <AudioContext.Provider value={{ state, dispatch }}>
      {children}
    </AudioContext.Provider>
  );
};

export const useAudio = () => {
  const context = useContext(AudioContext);
  if (!context) {
    throw new Error("useAudio must be used within an AudioProvider");
  }
  return context;
};
