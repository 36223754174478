import React from "react";
import "./src/styles/global.css";
import { AuthProvider } from "./src/contexts/AuthProvider/AuthProvider";
import { ToastProvider } from "./src/contexts/ToastProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import { UserProvider } from "./src/contexts/UserProvider";
import { EpisodeProvider } from "./src/contexts/EpisodeProvider";
import { ConfirmationProvider } from "./src/contexts/ConfirmationProvider";
import { IntervalProvider } from "./src/contexts/IntervalProvider";
import { SwipeProvider } from "./src/contexts/SwipeProvider";
import { AudioProvider } from "./src/contexts/AudioProvider";
import { WizardProvider } from "./src/contexts/WizardProvider";

// For debugging purposes - enable when needed
if (process.env.NODE_ENV === "development") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}

const queryClient = new QueryClient();

const ProvidersWrapper = ({ children }) => (
  <SwipeProvider>
    <ToastProvider>
      <ConfirmationProvider>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <UserProvider>
              <WizardProvider>
                <IntervalProvider>
                  <EpisodeProvider>
                    <AudioProvider>{children}</AudioProvider>
                  </EpisodeProvider>
                </IntervalProvider>
              </WizardProvider>
            </UserProvider>
          </AuthProvider>
        </QueryClientProvider>
      </ConfirmationProvider>
    </ToastProvider>
  </SwipeProvider>
);

export const wrapRootElement = ({ element }) => (
  <ProvidersWrapper>{element}</ProvidersWrapper>
);
