import {
  PublicClientApplication,
  BrowserCacheLocation,
} from "@azure/msal-browser";
import { LogLevel } from "@azure/msal-browser";
import { APP_CONFIG } from "../../utils/constants";
import { devLog } from "../../utils/utils";

export const b2cClientId = APP_CONFIG.B2C_CLIENT_ID || "";
export const b2cAuthority = APP_CONFIG.B2C_AUTHORITY || "";
export const b2cKnownAuthorities = APP_CONFIG.B2C_KNOWN_AUTHORITIES
  ? [APP_CONFIG.B2C_KNOWN_AUTHORITIES]
  : [];
export const b2cRedirectUri = APP_CONFIG.B2C_REDIRECT_URI || "";

export const msalConfig = {
  auth: {
    clientId: b2cClientId,
    authority: b2cAuthority,
    knownAuthorities: b2cKnownAuthorities,
    redirectUri: b2cRedirectUri + "/",
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage, 
    storeAuthStateInCookie: true,
  },
  system: {
    navigateToLoginRequestUrl: false,
    allowNativeBroker: false,
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            devLog(message);
            return;
          case LogLevel.Verbose:
            devLog(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const pca = new PublicClientApplication(msalConfig);
