// src/useProfiles.js
import { useQuery, useMutation, useQueryClient } from "react-query";
import { ApiPayloadType } from "../utils/utils";
import { useApiRequest } from "./useApi";

export function useProfile(profileParameters: any) {
  const makeApiRequest = useApiRequest();
  
  const profileQuery = useQuery(
    ["profile", profileParameters],
    async () => {      
      const payload: ApiPayloadType = {
        action: "GET_PROFILE",
        payload: profileParameters,
      };

      const response = await makeApiRequest.mutateAsync(payload); 

      return response;
    },
    { 
      enabled: !!profileParameters.atlasToken
    }
  );

  if (profileQuery.isError) {
    console.error(profileQuery.error);
  }

  return { ...profileQuery, refetch: profileQuery.refetch };
}

export function useUpdateProfile(atlasToken: string) {
  const queryClient = useQueryClient();
  const makeApiRequest = useApiRequest();

  const updateMutation = useMutation(
    async (user: any) => {
      if (!atlasToken) {
        throw new Error('No Atlas token available');
      }

      const updateData: ApiPayloadType = {
        action: "UPDATE_USER",
        payload: user,
      };

      const response = await makeApiRequest.mutateAsync(updateData); // using mutateAsync to get the Promise
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("profile");
      },
      onError: (error: Error) => {
        console.error(error);
      }
    }
  );

  return updateMutation;
}
