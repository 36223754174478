import React, { createContext, useContext, ReactNode } from 'react';
import { IntervalEntity } from '../utils/utils';
interface IntervalContextProps {
  intervals: IntervalEntity[];
  setIntervals: React.Dispatch<React.SetStateAction<IntervalEntity[]>>;
}


const IntervalContext = createContext<IntervalContextProps | undefined>(undefined);

export const IntervalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [intervals, setIntervals] = React.useState<IntervalEntity[]>([]);

  return (
    <IntervalContext.Provider value={{ intervals, setIntervals }}>
      {children}
    </IntervalContext.Provider>
  );
};

export const useIntervalContext = () => {
  const context = useContext(IntervalContext);
  if (context === undefined) {
    throw new Error('useIntervalContext must be used within a IntervalProvider');
  }
  return context;
};
